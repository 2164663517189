import React from "react";
function Parser(str){
    var result = [];
    var words = str.split('<br>');
    words.forEach(word =>{
        result.push(<p>{word}</p>)

    });
    return result;
}
export default Parser;