import React from "react";
import "./item.css"
import {Link} from "react-router-dom";

class Item extends React.Component 
{ 
        render (){
              try {
                var img = require("../data/res/"+this.props.item["res-dir"]+"/"+this.props.item["icon"])
              } catch (error) {
                
              }
              
              return (

              <Link to={'/project='+this.props.item["res-dir"]}>
              <div class="ml-6 mr-6 h-full">
              <div class=" w-full h-full  flex-col justify-center border-4 border-tertiaryT text-secondaryT bg-primaryT rounded-2xl shadow-xl
              transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 hover: duration-200 hover:bg-tertiaryHighlight hover:text-primaryT ">   
                <img class="aspect-video w-full rounded-t-2xl object-center" src={img}></img>
                <div class=" p-4">
                  <small class=" text-tertiaryT text-sm">{this.props.item["category"]}</small>
                  <h1 class="text-3xl font-medium pb-2">{this.props.item["name"]}</h1>
                  <p class=" text-sm tracking-tight font-light  leading-6
                  transition ease-in-out delay-150  hover:text-xl hover: duration-300">{this.props.item["short_description"]}</p>
                </div>
              </div>
              </div>
                </Link>
            
              
              
              )
        }
}
  

export default Item;