//IMAGES
import img1 from './res/pic_1.svg';
import githubIcon from './res/github.svg';
import githubBlank from './res/github-icon-1.svg';
import fiverr from './res/fiverr-1.svg';
import linkedin from './res/linkedin-icon.svg';
import ukflag from "./res/uk_flag.png"
//COMPONENT
import Item from './Components/item.js';
import MediaBubble from './Components/MediaBubble';
import TranslateBubble from './Components/TranslateBubble';
//LIBRARIES
import './App.css';
import animate from './tools/Animations'
import React,{useEffect, useState} from 'react';
import Parser from './tools/StringParser';
import colors from "tailwindcss/colors";
//DATA
import data from "./data/projects.json"
import summaryEn from "./data/summary.json"
import summaryFr from "./data/summary-fr.json";

//PAGE

function App() {
  var primaryColor
  var secondaryColor
  var tertiaryColor
  var hasfaded = false;

  const [summary,setSummary] = useState(summaryEn)


  function handleScroll(event){
    
    if(hasfaded) return;
    console.log("scroll");
    //Fade animation App-title
    var img = document.getElementById("header-img");
    var headers = document.getElementsByClassName("App-header")
    var titles = document.getElementsByClassName("App-title");
    var descs = document.getElementsByClassName("App-desc");
    var title = titles[0];
    var header = headers[0]
    var desc = descs[0];


    animate({
      duration: 750,
      timing(timeFraction){
        //Ease animation
        return (Math.exp(timeFraction)-1)
      },
      draw(progress){
        title.style.opacity = 1-progress;
        title.style.transform = "translateY(-"+progress*100+"%)";
      }
    }).then(() => {
      animate({
        duration: 750,
        timing(timeFraction){
          //Ease animation
          return (Math.exp(timeFraction)-1)
        },
        draw(progress){
          desc.style.opacity = progress;
        }
      })

    })
    var setting = getComputedStyle(img)
    var height = parseInt(setting.height);
    var header_height = parseInt(getComputedStyle(header).height)
    img.style.top = (header_height/2-height/2)+"px";
    img.style.bottom = '0px'

    animate({   
      duration: 2000,
      timing(timeFraction){
        //Ease animation
        return (Math.exp(timeFraction)-1)
      },
      draw(progress){
        img.style.opacity = progress;
      }
      });

    hasfaded = true
  }
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("click", handleScroll);
  // var hasbeenAsked=false;

  var items = data["items"]
  function displayItems()
  {
    var result = []
    var i = 0
    items.forEach(item => {
      result.push(<Item item={item} key={i}></Item>)
      i++
    });
    console.log(result)
    return result
  }

  function changingLanguage(isEnglish){
     console.log("change")

     if(isEnglish) setSummary(summaryEn)
     else setSummary(summaryFr)
  }

  return (

    <div id="App" className="App bg-primaryT">
      <header className="App-header">
        <div class="absolute top-0 w-full h-fit flex  justify-center">
          <MediaBubble dataId="1" link={"https://github.com/Z3ZEL"} img={githubBlank} ></MediaBubble>
          <MediaBubble dataId="2" link={"https://www.linkedin.com/in/esteban-rodriguez-32b918230/"} img={linkedin}></MediaBubble>
          <MediaBubble dataId="3" link={"https://fr.fiverr.com/zezel_?up_rollout=true"} img={fiverr} ></MediaBubble>
          <TranslateBubble dataId="4" link={"blank"} img={ukflag} onLangueChange={changingLanguage} />
        </div>



        <h1 className="App-title text-secondaryT">{summary.title}</h1>
        <p  className="App-desc text-secondaryT">{summary.description}</p> 

        <svg id="header-img" width="132.32mm" height="138.62mm" version="1.1" viewBox="0 0 132.32 138.62" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-39.496 -46.039)" stroke="var(--tertiary)">
          <g stroke-linejoin="round">
          <path transform="matrix(.7911 -.61169 .62986 .77671 0 0)" d="m90.888 157.59s-22.919 14.724-38.705 16.681c-16.356 2.0272-31.551-2.8254-32.351-14.732-.78513-11.694 13.024-19.99 29.459-19.99 16.435 0 41.597 18.042 41.597 18.042z" fill="var(--primary)" stroke-dasharray="33.0764, 1.37819" stroke-width="1.3782"/>
          <path d="m40.184 46.689s25.456 8.8574 36.631 20.113c11.579 11.662 17.105 26.74 8.3458 34.872-8.6026 7.9866-23.493 2.2876-33.546-10.714-10.053-13.001-11.431-44.271-11.431-44.271z" fill="var(--primary)" stroke-dasharray="33.072, 1.37801" stroke-width="1.378"/>
          <path d="m58.201 184.65a42.681 16.578 0 0142.681-16.578 42.681 16.578 0 0142.681 16.578" fill="var(--tertiary)" stroke-dasharray="23.16, 0.965" stroke-width=".965"/>
          </g>
          <path d="m99.823 168.52c-6.8783-50.088 41.799-79.806 41.799-79.806" fill="none" stroke-width="1.2"/>
          <path d="m99.823 168.52c-9.5964-81.241-36.756-92.788-36.756-92.788" fill="none" stroke-width="1.2"/>
        </g>
        </svg>
 

      </header>
      <div className="App-body">
        <div class="divider "></div>
        <h2 className='App-subtitle p-6 text-tertiaryT'>{summary.subtitle}</h2>
        <div class="divider "></div>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4">
        {displayItems()}
        </div>
      </div>  

      <div class="divider "></div>
      <div className="App-footer">
      <footer class="footer footer-center mt-10 p-10 bg-primaryT border-4 border-tertiaryT text-tertiaryT">
        <div class="text-tertiaryT">
          <p class="font-bold ">
            {summary['contact-bold']}
          </p> 
          {Parser(summary['contact-light'])}
  
        </div> 
        
      </footer>
      </div>
    </div>
  );
  

}

export default App;
