import React from "react";
import "./item.css"
import animate from "../tools/Animations";


class MediaBubble extends React.Component 
{ 
    constructor(){
        super()
        this.hasbeenInit = false;
        this.gap = 150;
        this.state= {
            hover:false
        }
        this.item = undefined
    }

   


    floating(){
        //GET ITEMS
        var item = document.getElementById(this.id)
        //GET RANDOM VALUE
        var amplitudeGap = [20,80]
        var amplitude = amplitudeGap[0] + Math.random()*(amplitudeGap[1]-amplitudeGap[0])
        var speedGap = [3,8]
        var speed = speedGap[0] + Math.random()*(speedGap[1]-speedGap[0])
        //ANIMATE FLOATING
        animate({
            duration:speed*1000,
            timing(timeFraction){
                var x=timeFraction
                const c1 = 1.70158;
                const c2 = c1 * 1.525;

                return x < 0.5
                ? (Math.pow(2 * x, 2) * ((c2 + 1) * 2 * x - c2)) / 2
                : (Math.pow(2 * x - 2, 2) * ((c2 + 1) * (x * 2 - 2) + c2) + 2) / 2;
            },
            draw(progress){

                item.style.transform = "translateY("+amplitude*progress+"px)"


            }
        }).then(() => {
            this.floating()
        })


    }
    zoomIn(){
        console.log("zoom")
        var item = document.getElementById(this.id)
        animate({
            duration:500,
            timing(timeFraction){
                return (Math.exp(timeFraction)-1)
            },
            draw(progress){
                item.style.transform = "scale("+progress+")"
            }
        });

    }
    set(){
        var item = document.getElementById(this.id)
        var gap =this.gap;
        item.style.transform = "translateY("+gap+"px)"
        item.style.opacity = 0;

     
    }
    display(id){
        if(this.hasbeenInit) return;
        this.hasbeenInit = true;
        var item = document.getElementById(id)
        var gap = this.gap;
        animate({
            duration:750,
            timing(timeFraction){
                return timeFraction === 0 ? 0 : Math.pow(2, 10 * timeFraction - 10);
            },
            draw(progress){
                item.style.opacity = progress;
                item.style.transform = "translateY(-"+(gap*(1-progress))+"px)"
            }
        })
    }
    openLink(){
        window.open(this.props.link)
    }

    componentDidMount(){
        window.addEventListener("scroll", () => {this.display(this.id)});
        window.addEventListener("click", () => {this.display(this.id)});
        var item = document.getElementById(this.id)
        item.style.zIndex = "20"
        this.floating()
        this.set()
    
    }

    render(){
        if(this.state.hover) {
            this.zoomIn()
            this.setState({hover:false})
        }
        this.id = "media-"+this.props.dataId;
        return(
          <div id={this.id} 
          onClick={()=>{this.openLink()}}
           class=" w-32 aspect-square  border-4 rounded-full border-tertiaryT transition ease-in-out m-5"
           onMouseEnter={()=>{this.setState({hover:true})}}
           >
            <img class=" object-cover  p-3" src={this.props.img} />

          </div>

        )
    }
}
export default MediaBubble;