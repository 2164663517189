import "./Project.css"
import React from "react";
import { useParams } from "react-router";
import data from "../data/projects.json"

function Project()
{
   let { projectName } = useParams();




   //PARSE ITEM
   var items = data["items"];
   projectName = projectName.replace("=","");
   var item = items.find(item => item["res-dir"] === projectName);
   if(item === undefined)
   {
      return(<h1>Project not found : {projectName}</h1>)
   }else{
      //IMPORT ALL IMAGES
      var imgs = [];
      var imgLinks = item["images"]

      imgLinks.forEach(imgLink => {
         var temp = require("../data/res/"+item["res-dir"]+"/"+imgLink);
         imgs.push(temp);
      })
      return(
         <div class="bg-primaryT text-secondaryT">
            <p class="text-center p-5 justify-center text-7xl">{item["name"]}</p>

            <div class="divider"></div>
            <p class="w-full text-justify text-4xl p-8">
               {item["long_description"]}
               
            </p>

            <div class="divider"></div>
            <div class="grid grid-flow-row-dense lg:grid-cols-2 gap-6 md:grid-cols-1 justify-center">
               {GetAllImages(imgs)}
            </div>
            <div class="divider"></div>
            <p class="text-center p-5 justify-center text-5xl">Links</p>
            <div class="flex place-content-center lg:grid-cols-4 md:grid-cols-1 justify-center">
               {GetAllLinks(item)}
            </div>
           
            

         </div>
      )
   }


}

function GetAllImages(imgs){
   var result = []
   var i = 0
   imgs.forEach(img=>{
         result.push(
            <div key={i} class="mr-6 ml-6 flex self-center justify-center">
                  <img src={img} class="object-center lg:w-5/6 md:w-1/2 rounded-xl transition ease-in-out delay-150 hover: duration-800 hover:scale-110 " alt="" />  
            </div> 
         )
         i++

   })
   return result
}
function GetAllLinks(item){
   var result = []
   var i = 0
   Object.keys(item["links"]).forEach(key=>{
      
      result.push(
         <div key={i} class="mb-6 mr-6 ml-6 w-5/6">
            <button onClick={() => {openLink(item["links"][key])}} class=" btn w-full bg-tertiaryT text-primaryT hover:bg-tertiaryHighlight hover:scale-110 hover:duration-300">{key}</button>
         </div>
      )
      i++
   })
   return result
}
function openLink(link){
   window.open(link,"_blank");
}

export default Project;