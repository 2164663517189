export default function animate({timing, draw, duration}) {
    return new Promise((resolve, reject) => {
      let start = performance.now();
  
      requestAnimationFrame(function animate(time) {
        // timeFraction passe de 0 à 1
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) timeFraction = 1;
    
        // calculer l'état courant de l'animation
        let progress = timing(timeFraction)
    
        draw(progress); // dessinez-le
    
        if (timeFraction < 1) {
          requestAnimationFrame(animate);
        }else{
          resolve();
        }
    
      });

    })
}



