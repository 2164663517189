import React from "react";
import MediaBubble from "./MediaBubble";

import frenchflag from "../res/french_flag.svg"
import ukflag from "../res/uk_flag.png"

class TranslateBubble extends MediaBubble{
    constructor(){

        super();
 
        this.img = ukflag;
        this.state = {
            hover:false
        }
    }
    openLink(){
        this.isEnglish = !this.isEnglish;
        this.props.onLangueChange(this.isEnglish)
        if(this.isEnglish){
            this.img = ukflag
        }else this.img = frenchflag;

        this.forceUpdate()

    }

    

    render(){

        if(this.state.hover) {
            this.zoomIn()
            this.setState({hover:false})
        }
        this.id = "media-"+this.props.dataId;
        return(
          <div id={this.id} 
          onClick={()=>{this.openLink()}}
           class=" w-32 aspect-square  border-4 rounded-full border-tertiaryT transition ease-in-out m-5"
           onMouseEnter={()=>{this.setState({hover:true})}}
           >
            <img class=" object-fill rounded-full h-full w-full  p-3" src={this.img} />

          </div>

        )
    }
}

export default TranslateBubble;